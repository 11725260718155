import { message, Spin } from "antd";
import { isEmpty } from "lodash";
import get from "lodash.get";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { feathers } from "../../../api/feathers_rest";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import { triggerModal } from "../../../redux/actions/app-actions";
import { loginSuccessful } from "../../../redux/actions/user-actions";
import { formatAmount, formatNumber } from "../../../utilities/common-function";
import Layout from "../../general/components/Layout";
import NavHeader from "../../general/components/NavHeader";

// markup

const DepositDetails = (props) => {
  const accessKey = get(props.user, "accessKey");
  // const location = useLocation();
  const { t } = useTranslation();
  // const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [deposit, setDeposit] = useState({});
  const [binanceAddress, setBinanceAddress] = useState("");
  const user = get(props, "user.user");

  function getData() {
    if (get(props, "product.depositActiveId")) {
      feathers("deposit-transactions", accessKey)
        .find({
          _id: props.product.depositActiveId,
        })
        .then((res) => {
          if (get(res, "data")) {
            setDeposit(...get(res, "data"));
          }
        })
        .catch((err) => {
          message.error("something went wrong.");
        });
    }
  }

  function getAddress() {
    feathers("system-configuration", accessKey)
      .find({ key: "binanceWalletLinkAddress" })
      .then((res) => {
        let result = get(res, "result[0]");
        setBinanceAddress(get(result, "binanceWalletLinkAddress"));
      })
      .catch((err) => {});
  }

  useEffect(() => {
    getData();
    getAddress();
  }, [props.product]);

  const _renderType = (type) => {
    switch (type) {
      case 2:
        // return "审核通过 Deposit Approved";
        return t("depositApproved", sourceKey.asset);
      case 1:
        // return "审核拒绝  Deposit Rejected";
        return t("depositRejected", sourceKey.asset);
      case 0:
        // return "审核中  Deposit Under Review";
        return t("depositUnderReview", sourceKey.asset);

      default:
        break;
    }
  };

  let images = get(deposit, "images");

  return (
    <React.Fragment>
      <Layout>
        <Spin spinning={loading}>
          <NavHeader
            suffix={
              <div className="justify-end flex items-center">
                {/* 充币详情  <MediaQuery maxWidth={440}><br/></MediaQuery> */}
                {t("depositDetails", sourceKey.asset)}
              </div>
            }
            showBack={true}
          >
            <div className="p-4 mx-3">
              <span className="font-semibold text-2xl text-blue-400">
                {formatAmount(get(deposit, "amount"))} USDT
              </span>

              <div className="flex justify-between my-3 items-center border-b py-2">
                <span className="text-gray-500 inline-block w-1/3 mr-3">
                  {/* 区块链交易ID <br/>  */}
                  {t("transactionId", sourceKey.asset)}
                </span>
                <span className="inline-block w-2/3 text-right">
                  {get(deposit, "transactionId")}
                </span>
              </div>
              <div className="flex justify-between my-3 items-center border-b py-2">
                <span className="text-gray-500 inline-block w-1/3 mr-3">
                  UID
                </span>
                <span className="inline-block w-2/3 text-right">
                  {get(user, "referralCode")}
                </span>
              </div>
              <div className="flex justify-between my-3 items-center border-b py-2">
                <span className="text-gray-500 inline-block w-1/3 mr-3">
                  {/* 充币数量<br/>  */}
                  {t("reloadAmount", sourceKey.asset)}
                </span>
                <span className="inline-block w-2/3 text-right">
                  {formatNumber(get(deposit, "amount"), null, true, 8, true)}
                </span>
              </div>
              <div className="flex justify-between my-3 items-center border-b py-2">
                <span className="text-gray-500 inline-block w-1/3 mr-3">
                  {/* 转出地址 <br/>  */}
                  {t("chargeAddress", sourceKey.asset)}
                </span>
                <span
                  className="inline-block w-2/3 text-right"
                  style={{ overflowWrap: "break-word" }}
                >
                  {get(deposit, "chargeAddress")}
                </span>
              </div>
              <div className="flex justify-between my-3 items-center border-b py-2">
                <span className="text-gray-500 inline-block w-1/3 mr-3">
                  {/* 状态 <br/>  */}
                  {t("status", sourceKey.asset)}
                </span>
                <span className="inline-block w-2/3 text-right">
                  {_renderType(get(deposit, "status"))}
                </span>
              </div>
              <div className="flex justify-between my-3 items-center border-b py-2">
                <span className="text-gray-500 inline-block w-1/3 mr-3">
                  {/* 时间  <br/> */}
                  {t("date", sourceKey.asset)}
                </span>
                <span className="inline-block w-2/3 text-right">
                  {get(deposit, "transactionDate")
                    ? moment(get(deposit, "transactionDate")).format(
                        "DD/MM/YYYY hh:mm:ss A"
                      )
                    : ""}
                </span>
              </div>
              <div className="flex justify-between my-3 items-center border-b py-2">
                <span className="text-gray-500 inline-block w-1/3 mr-3">
                  {/* 备注 <br/>  */}
                  {t("remark", sourceKey.asset)}
                </span>
                <span className="inline-block w-2/3 text-right">
                  {isEmpty(get(deposit, "remark"))
                    ? "-"
                    : get(deposit, "remark")}
                </span>
              </div>
              <div className="flex justify-between my-3 items-center border-b py-2">
                <span className="text-gray-500 inline-block w-1/3 mr-3">
                  {/* 照片 <br/>  */}
                  {t("image", sourceKey.asset)}
                </span>
                <span className="inline-block w-2/3 text-right">
                  {images?.map((i) => {
                    return (
                      <img
                        src={get(i, "url")}
                        style={{
                          width: 550,
                          height: 400,
                        }}
                        className="img-cover"
                      />
                    );
                  })}
                </span>
              </div>
            </div>
          </NavHeader>
        </Spin>
      </Layout>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  product: state.product,
});

const mapDispatchToProps = {
  loginSuccessful,
  triggerModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(DepositDetails);
